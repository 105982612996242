<template>
  <div class="poi-picker">
    <div class="picker-header">
      <div class="picker-btn">
        <div class="cancel" @click="onCancel">取消</div>
        <div class="confirm" :class="{ disabled: !selectedPOI }" @click="onConfirm">确定</div>
      </div>
      <div class="picker-title" v-if="!searching">
        <div class="aoi-title">
          <span>AOI</span>
          <span class="search-trigger" @click="searching = true">
            <img src="@/assets/imgs/input-search.png" alt="" />
            <span>搜索AOI</span>
          </span>
        </div>
        <div class="poi-title">POI</div>
      </div>
      <div class="searchBar" v-else>
        <div class="searchBar-input">
          <img src="@/assets/imgs/input-search.png" alt="" />
          <input
            @input="onInputChange"
            type="text"
            placeholder="请输入AOI名称"
            v-model="searchValue"
          />
          <span>
            <img
              v-if="searchValue"
              @click="clearSearchInput"
              src="@/assets/imgs/input-clear.png"
              alt=""
            />
          </span>
        </div>
        <div class="searchBar-btn" @click="cancelSearch">取消</div>
      </div>
    </div>
    <div v-show="!searching" class="picker-body">
      <ul class="aoi-list">
        <li
          v-for="item in dataList"
          :key="item.parkCode"
          @click="onSelectAOI(item)"
          :class="{ active: selectedAOI?.parkCode === item.parkCode }"
        >
          {{ item.parkName }}
        </li>
      </ul>
      <ul class="poi-list" v-if="selectedAOI">
        <li
          v-for="item in selectedAOI.poiList"
          :key="item.stationId"
          :class="{ active: selectedPOI?.stationId === item.stationId }"
          @click="onSelectPOI(item)"
        >
          {{ item.stationName }}
        </li>
      </ul>
    </div>
    <div v-show="searching" class="search-result">
      <ul class="aoi-list">
        <li
          v-for="item in dataList.filter((item) => item.parkName.includes(filterValue))"
          :key="item.parkCode"
          @click="onSearchAOI(item)"
        >
          <div v-html="itemName(item)"></div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { debounce } from '@/utils/index';
export default {
  props: ['selected', 'dataList'],
  data() {
    return {
      selectedAOI: null,
      selectedPOI: null,
      poiList: [],
      timestamp: Date.now(),
      searching: false,
      searchValue: '',
      filterValue: '', // 用来处理防抖
    };
  },
  watch: {
    selected: {
      handler(value, old) {
        if (old === undefined && value[0]) {
          this.selectedAOI = value[0];
          this.selectedPOI = value[1];
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearSearchInput() {
      this.searchValue = '';
      console.log(this.searchValue);
      this.filterValue = '';
    },
    itemName(item) {
      let str = this.filterValue;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      return item.parkName.replace(reg, replaceName);
    },
    onInputChange(e) {
      this.filterValue = e.target.value;
    },
    cancelSearch() {
      this.searchValue = '';
      this.filterValue = ''; // 用来防抖
      this.searching = false;
    },
    scrollIntoView({ parkCode }) {
      const index = this.dataList.findIndex((item) => item.parkCode === parkCode);
      const container = document.querySelector('.picker-body > .aoi-list');
      const currentLi = document.querySelectorAll('.picker-body > .aoi-list > li')[index];
      container.scrollTop = currentLi.offsetTop - container.clientHeight + currentLi.clientHeight;
    },
    onSearchAOI(item) {
      this.searching = false;
      this.filterValue = '';
      if (this.selectedAOI?.parkCode === item.parkCode) return;
      this.searchValue = '';
      this.selectedAOI = item;
      this.selectedPOI = null;
      this.$nextTick(() => {
        this.scrollIntoView(item);
      });
    },
    onSelectAOI(item) {
      if (this.selectedAOI?.parkCode === item.parkCode) return;
      this.selectedAOI = item;
      this.selectedPOI = null;
    },
    onSelectPOI(item) {
      this.selectedPOI = item;
    },
    onConfirm() {
      if (!this.selectedPOI) return;
      this.$emit('confirm', [this.selectedAOI, this.selectedPOI]);
    },
    onCancel() {
      this.searching = false;
      this.$emit('cancel');
      // 如果点了取消，把上次数据回显
      if (this.selected && this.selected.length && this.selected[0]) {
        this.selectedAOI = this.selected[0];
        this.selectedPOI = this.selected[1];
      } else {
        this.selectedAOI = null;
        this.selectedPOI = null;
      }
    },
  },
  created() {
    this.onInputChange = debounce(this.onInputChange, 500);
  },
};
</script>
<style lang="scss" scoped>
.poi-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  .picker-header {
    height: 84px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fafafa;
    .picker-btn {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      > div {
        height: 100%;
        line-height: 42px;
      }
      .confirm {
        color: rgba(67, 110, 255, 1);
        &.disabled {
          color: rgba(151, 152, 171, 1);
        }
      }
    }

    .picker-title {
      height: 42px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      gap: 16px;
      .aoi-title {
        flex: 2 0 40%;
        display: flex;
        align-items: center;

        .search-trigger {
          margin-left: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 9px 5px 5px;
          border-radius: 6px;
          background: rgba(255, 255, 255, 1);

          border: 1px solid rgba(235, 235, 235, 1);
          font-size: 12px;
          font-weight: 400;
          color: rgba(191, 192, 203, 1);
          img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }
      .poi-title {
        flex: 3 1 60%;
        width: 58%;
      }
    }

    .searchBar {
      height: 42px;
      display: flex;
      align-items: center;
      .searchBar-input {
        flex: 1;
        height: 32px;
        background: white;
        display: flex;
        align-items: center;
        border-radius: 6px;
        margin-right: 10px;
        > img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
          margin-left: 8px;
        }
        input {
          flex: 1;
          caret-color: rgba(70, 95, 253, 1);
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: rgba(151, 152, 171, 1);
          }
        }

        span {
          display: flex;
          img {
            width: 14px;
            height: 14px;
            line-height: 100%;
            margin: 6px;
          }
        }
      }

      .searchBar-btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .picker-body {
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    overflow: hidden;
    gap: 16px;
    .aoi-list {
      flex: 2 0 40%;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        box-sizing: border-box;
        padding: 12px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.active {
          color: #465ffd;
        }
      }
    }

    .poi-list {
      flex: 3 1 60%;

      overflow: auto;
      li {
        box-sizing: border-box;
        padding: 12px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.active {
          color: #465ffd;
        }
      }
    }
  }

  .search-result {
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    overflow: hidden;
    gap: 16px;
    .aoi-list {
      flex: 2 0 40%;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        box-sizing: border-box;
        padding: 12px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-bottom: 1px solid rgba(235, 235, 235, 1);
      }
    }
  }
}
</style>
